import React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Clyde.
            <br className="hidden lg:inline-block" />Self-driven, Curious & Passionate. Programmer • Writer • History Aficionado.
          </h1>
          <p className="mb-8 leading-relaxed">
          Meticulous with a keen eye for detail is one way I would describe myself. Vehemently curious is another. Flexible, I'm someone who learns through observation & can adapt to anything thrown at me. My skills range from programming to writing, and my interests range from history to science & everything in between. I spend my free time reading novels and researching content for my history blog.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              Work With Me
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              See My Past Work
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./clyde_noronha.jpg"
          />
        </div>
      </div>
    </section>
  );
}