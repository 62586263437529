export const projects = [
  {
    title: "React Reserve",
    subtitle: "MERN Stack",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./project-1.gif",
    link: "https://reactbootcamp.com",
  },
  {
    title: "React Tracks",
    subtitle: "React and Python",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./project-2.gif",
    link: "https://reactbootcamp.com",
  },
  {
    title: "DevChat",
    subtitle: "React and Firebase",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./project-3.gif",
    link: "https://reactbootcamp.com",
  },
  {
    title: "Epic Todo App",
    subtitle: "React Hooks",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./project-4.gif",
    link: "https://reactbootcamp.com",
  },
];

export const endorsements = [
  {
    quote: "Clyde had interned at my company, Planet Earthlings, as a programmer. It was a pleasure working with Clyde, he was always regular with his work, and communicated effectively with the team he was assigned to. Clyde has been a valuable asset to my company, and I am sure that his work ethic and flexibility will lead him to success in his future career goals.",
    image: "./greg_acuna.jpg",
    name: "Greg Acuna",
    company: "Planet Earthlings",
  },
  {
    quote: "Clyde's a great and dependable guy who is driven, committed and focused in achieving his goals with objectivity and professionalism, We worked together on a side-scroller using Unity for a project where Clyde demonstrated great aptitude as a programmer with a great eye for detail and optimisation. I learned a lot from working with Clyde and look forward to work with him in future projects.",
    image: "./reuben.jpg",
    name: "Reuben Bocarro",
  },
];

export const skills = [
  "Node.js",
  "React",
  "JavaScript",
  "SQL",
  "Python",
  "Java",
  "AWS Services",
  "ElasticSearch",
  "Kafka"
];
